import React, { Fragment } from 'react';

import '../styles/style.scss';
import { IconLogoAlt } from 'components/Icon';
import Button from 'components/Button';
import { ButtonVariants } from 'components/Button/constants';
import { FormattedMessage, IntlProvider } from 'react-intl';
import usersLocale from 'utilities/locale';
import defaultTranslations from 'lang/en.json';

const ErrorPage = () => (
    <IntlProvider
        locale={usersLocale}
        messages={defaultTranslations}
        textComponent={Fragment}
    >
        <main className="error-page">
            <section className="error-page__content">
                <span className="error-page__icon">
                    4
                    <div className="error-page__icon-holder">
                        <IconLogoAlt color="#353535" />
                    </div>
                    4
                </span>
                <h1 className="error-page__title">
                    <FormattedMessage id="ERROR_TITLE" />
                </h1>
                <p className="error-page__text">
                    <FormattedMessage id="ERROR_TEXT" />
                </p>
                <Button href="/" variant={ButtonVariants.PRIMARY}>
                    <FormattedMessage id="ERROR_BUTTON" />
                </Button>
            </section>
        </main>
    </IntlProvider>
);

export default ErrorPage;
